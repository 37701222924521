html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: 'Lato', sans-serif;
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  min-height: 100vh;
}
